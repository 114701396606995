import React from "react"
import { Card, CardBody } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NetlifyForm from "react-netlify-form"

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact Me">
      <SEO
        title="Contact Me"
        
      />
      <Card>
        <CardBody className="marginated">
          <div  className="padded">
          <h5>Use the form below if you:</h5>
          <ul>
            <li>Want to chat or discuss something!</li>
            <li>Have a suggestion for my next project!</li>
            <li>Found an error in a published article/project!</li>
            <li>Want to hire me!</li>
            <li>Have business inquiries!</li>
            <li>Want to leave a general feedback!</li>
            <li>Want to thank me!</li>
            <li>Anything really...</li>

          </ul>
          <h6> And I'll get back to you ASAP!</h6>
          
          <div style={{paddingTop:"50px"}}>
          
          <NetlifyForm name="Contact Form" recaptcha={{sitekey: "6Ld-ycsUAAAAAK3vbsEGsMY7QfFwMd5TR9WTVL8O", size: "normal"}}>
            {({ loading, recaptchaError, error, success, recaptcha }) => (
              <div>
                {loading && <div>Loading...</div>}
                {recaptchaError &&
                <div>Recaptcha did not match. Please make sure the box is checked.</div>
                }
                {error && (
                  <div>
                    Your information was not sent. Please try again later.
                  </div>
                )}
                {success && <div>Thank you for contacting me!</div>}
                {!loading && !success && (
                  // ADD YOUR CUSTOM FORMS HERE
                  <div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Your name
                          </label>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="John Doe"
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="email">
                            Your email
                          </label>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            
                            placeholder="name@example.com"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Message</label>
                        <textarea
                          name="message"
                          id="message"
                          className="form-control"
                          
                          rows="3"
                        />
                      </div>
                      {recaptcha}
                      <button style={{marginTop:"10px"}} type="submit" className="btn btn-primary mb-2">
                        Submit
                      </button>
                    </div>
                  </div>

                  //YOUR CUSTOM FORMS END HERE
                )}
              </div>
            )}
          </NetlifyForm>
          </div>
          </div>
        </CardBody>
      </Card>
    </Layout>
  )
}

export default ContactPage
